body {
  background-color: #4D4D4D;
}

.App {
  text-align: center;
}

.logo {
  width: 100%;
}

.help-block {
  font-size: 0.8em;
  color: red;
  margin: 1em;
}

.edit-input {
  margin-bottom: 1rem;
}

.btn-margins {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table-name {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tr-clickable {
  cursor: pointer;
}

.filter-input {
  width: 35%;
  display: inline;
  margin-left: 1rem;
}

.section-background {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.add-item-form {
  padding-top: 1rem;
  padding-bottom: 0.1rem;
}

.edit-buttons {
  margin-bottom: 1rem;
}

.login-dialog {
  position: relative;
  top: 5rem;
}

.margin-left {
  margin-left: 1rem;
}

.tenant-input {
  background-color: #f8f9fa;
  margin-top: 1rem;
  border-radius: 0.25rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
